/*
 * @Descripttion: 主入口
 * @Author: zyr
 * @Date: 2021-05-27 20:43:31
 * @LastEditors: zyr
 * @LastEditTime: 2021-09-16 09:54:56
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import AXIOS from '@/assets/js/axios'

import {
  Toast, Field, Form, Button, Icon, Swipe, SwipeItem, Switch,
  ActionSheet, Popup, Empty, Loading, Radio, RadioGroup
} from 'vant'
import 'vant/lib/index.css'

Vue.use(Field)
Vue.use(Form)
Vue.use(Button)
Vue.use(Icon)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(ActionSheet)
Vue.use(Popup)
Vue.use(Empty)
Vue.use(Loading)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Switch)

Vue.config.productionTip = false
Vue.prototype.$toast = Toast
Vue.prototype.$axios = AXIOS

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
