/*
 * @Descripttion:封装axios
 * @Author: zyr
 * @Date: 2021-05-30 00:58:05
 * @LastEditors: zyr
 * @LastEditTime: 2021-07-15 12:51:35
 */
import axios from 'axios'
import store from '../../store/index'
import router from '../../router/index'

const $axios = axios.create({ timeout: 4000 })

// 请求拦截
$axios.interceptors.request.use(config => {
  return config
}, err => Promise.reject(err))

// 响应拦截
$axios.interceptors.response.use(res => {
  return res.data
}, err => {
  if (err.response) {
    const { data: { code } } = err.response
    if (code === 3) {
      localStorage.removeItem('token')
      localStorage.removeItem('ability')
      store.commit('setToken', '')
      store.commit('setAbility', '')
      router.push('/login')
    }
  }
  return Promise.reject(err)
})

export default $axios
