/*
 * @Descripttion: 数据存储
 * @Author: zyr
 * @Date: 2021-05-27 20:43:31
 * @LastEditors: zyr
 * @LastEditTime: 2021-06-01 23:27:10
 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token') || '',
    ability: localStorage.getItem('ability') === 'true',
    id: localStorage.getItem('id') || '', // 用于写信
    page: '', // 记录页面
    phoneInfo: { phone: '', code: '' } // 重置密码
  },
  mutations: {
    setToken (state, token) {
      state.token = token
    },
    setId (state, id) {
      state.id = id
    },
    setPage (state, page) {
      state.page = page
    },
    setPhoneInfo (state, phoneInfo) {
      state.phoneInfo = phoneInfo
    },
    setAbility (state, ability) {
      state.ability = ability
    }
  },
  actions: {
  },
  modules: {
  }
})
