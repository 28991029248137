<!--
 * @Descripttion: 主页面
 * @Author: zyr
 * @Date: 2021-05-27 20:43:31
 * @LastEditors: zyr
 * @LastEditTime: 2021-07-15 14:13:56
-->
<template>
  <div id="app">
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>
<script>
export default {
  name: 'app',
  data () {
    return {
      list: [
        { name: 'Home', title: '信箱' },
        { name: 'Login', title: '信箱' },
        { name: 'Find', title: '信箱' },
        { name: 'Reset', title: '信箱' },
        { name: 'Register', title: '注册' },
        { name: 'Write', title: '我要写信' },
        { name: 'Reply', title: '回复查询' },
        { name: 'Detail', title: '回复查询' },
        { name: 'Guide', title: '操作指南' },
        { name: 'Error', title: '错误页面' },
        { name: 'Letter', title: '来信选刊' },
        { name: 'LetterDetail', title: '详情' }
      ]
    }
  },
  watch: {
    $route: function (to) {
      const { name } = to
      const item = this.list.find(v => v.name === name)
      this.handleTitle(item ? item.title : '')
    }
  },
  methods: {
    /* 修改标题 ios兼容 */
    handleTitle (title) {
      document.title = title
      if (/iphone/i.test(navigator.userAgent)) {
        const iframe = document.createElement('iframe')
        iframe.style.display = 'none'
        iframe.onload = () => {
          setTimeout(() => {
            iframe.remove()
          }, 0)
        }
        iframe.src = ''
        document.body.appendChild(iframe)
      }
    }
  }
}
</script>

<style lang="less">
#app{
  font-family: PingFangSC-Medium, PingFang SC;
}
#app::-webkit-scrollbar{
  width: 0 !important;
}
</style>
