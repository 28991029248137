/*
 * @Descripttion: 路由控制
 * @Author: zyr
 * @Date: 2021-05-27 20:43:31
 * @LastEditors: zyr
 * @LastEditTime: 2021-07-15 14:11:20
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'Error',
    component: () => import('../views/Error')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login')
  },
  {
    path: '/find',
    name: 'Find',
    component: () => import('../views/FindPass')
  },
  {
    path: '/reset',
    name: 'Reset',
    component: () => import('../views/Reset')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register')
  },
  {
    path: '/write',
    name: 'Write',
    component: () => import('../views/Write')
  },
  {
    path: '/guide',
    name: 'Guide',
    component: () => import('../views/Guide')
  },
  {
    path: '/reply',
    name: 'Reply',
    component: () => import('../views/Reply')
  },
  {
    path: '/detail',
    name: 'Detail',
    component: () => import('../views/Detail')
  },
  {
    path: '/letter',
    name: 'Letter',
    component: () => import('../views/Letter')
  },
  {
    path: '/letterdetail',
    name: 'LetterDetail',
    component: () => import('../views/Letter/Detail')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
